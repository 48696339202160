<template>
    <div>
        <div id="fake-container">
            <b-img id="noteshistory-banner" src="../assets/Home_Banner.png"></b-img>
        </div>
        <!-- NOTES HISTORY HEADER -->
        <div id="noteshistory-box" v-if="user != ''">
            <b-row align-v="center" align-h="center" class="max-100">
                <div class="noteshistory-secondary">
                    NOTES HISTORY
                </div>
            </b-row>
        </div>
        <!-- HISTORY SELECTOR -->
        <template>
            <div id="noteshistory-selector" v-if="user != ''">
                <span>Days Back:</span>
                <span id="selector">
                    <select v-model="selected" v-on:change="refreshNotes">
                        <option v-for="(option, idx) in options" :key="idx" v-bind:value="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </span>
            </div>
        </template>

        <div class="content" v-if="user != ''">

            <!-- ERROR MESSAGE -->
            <b-row id="errormsg" v-if="error" align-h="center" class="max-100"><b>{{ error }}</b></b-row>

            <!-- NOTES -->
            <div id="noteshistory-results">
                <div id="noteshistory-container">
                    <div class="noteshistory-section">
                        <div class="note" v-for="(note, obj) in usernotehistory" v-bind:key="obj">
                            <div class="notehistory-bold notehistory-name">{{ note.name }}</div>
                            <div>{{ note.entered_date }}</div>
                            <div class="notehistory-nullackby" v-if="note.ack_by == null">Not Acknowledged</div>
                            <div class="notehistory-ackby" v-if="note.ack_by != null">Acknowledged by {{ note.ack_by}}: {{ note.ack_date }}</div>
                            <div class="notehistory-note">{{ note.note }}</div>
                        </div>

                        <div id="noteshistory-hidden-div"></div>

                    </div>
                </div>
            </div>
            <div id="noteshistory-list-arrow-more">
                <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
            </div>

        </div>
        <div class="footer">
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'

library.add(faLongArrowAltDown)

export default {

    name: 'noteshistory',

    data: function() {
        return {
            selected: '30',
            options: [
              { text: '30 Days', value: '30' },
              { text: '60 Days', value: '60' },
              { text: '90 Days', value: '90' },
              { text: '180 Days', value: '180' },
              { text: '1 year', value: '365' }
            ]
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            error: 'noteshistory/error',
            usernotehistory: 'noteshistory/usernotehistory',
            notehistoryscope: 'noteshistory/notehistoryscope'
        }),

    },

    methods: {
        ...mapActions({
            setNoteHistoryScope: 'noteshistory/setNoteHistoryScope',
            getNotesHistory: 'noteshistory/getNotesHistory',
            turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
            turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg'
        }),

        flashList (element_array) {
            if(this.endOfListVisible(element_array)) {
                setTimeout(function() {
                        document.getElementById(element_array.containerName).classList.add('flash');
                        document.getElementById(element_array.listArrowName).classList.add('flash');
                        setTimeout(function() {
                            document.getElementById(element_array.containerName).classList.remove('flash');
                            document.getElementById(element_array.listArrowName).classList.remove('flash');
                            setTimeout(function() {
                                document.getElementById(element_array.containerName).classList.add('flash');
                                document.getElementById(element_array.listArrowName).classList.add('flash');
                            },100)
                        },250)
                    },400)
            }
        },

        onScroll(element_array) {
            // console.log(element_array)
        window.document.getElementById(element_array.containerName)
            .addEventListener("scroll", () => this.endOfListVisible(element_array));
        },

        endOfListVisible(element_array) {
            // console.log(element_array.hiddenDivName)
            var endoflist = document.getElementById(element_array.hiddenDivName).getBoundingClientRect();
            var listarrow = document.getElementById(element_array.listArrowName).getBoundingClientRect();
            // console.log(endoflist.bottom+' '+listarrow.bottom);
            if((endoflist.bottom - 60) > listarrow.bottom) {
                document.getElementById(element_array.listArrowName).style.visibility = 'visible';
                return true;
            } else {
                document.getElementById(element_array.listArrowName).style.visibility = 'hidden';
                return false;
            }

        },

        refreshNotes() {
            this.setNoteHistoryScope(this.selected);
            this.turnOnLoadingMsg().then(() => {

                this.getNotesHistory(this.user).then(() => {
                    this.turnOffLoadingMsg().then(() => {
                        var element_array = {
                            containerName: 'noteshistory-container',
                            listArrowName: 'noteshistory-list-arrow-more',
                            hiddenDivName: 'noteshistory-hidden-div'
                        }
                        this.flashList(element_array);
                        this.onScroll(element_array);
                    })

                });

            });

        }
    }

}
</script>

<style scoped>
#fake-container {
    position: fixed;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 40vh;
    max-height: 40vh;
    z-index: 0;
}

#noteshistory-banner {
    vertical-align: middle;
    border-style: none;
    min-height: 40vh;
    max-height: 40vh;
    min-width: 100%;
    float: right;
    z-index: 0;
}

#errormsg {
    background-color: white;
    margin-left: 5vw;
    min-width: 90vw;
    max-width: 90vw;
    margin-bottom: 2vh;
    color: red;
}

#noteshistory-box {
    position: fixed;
    color:white;
    top: 3.5em;
    min-height: 3.5em;
    width: 100vw;
    z-index: 100;

}

.noteshistory-secondary {
    text-align: center;
    width: 95%;
    font-size: 1.25em;
    font-weight: bold;
    padding-left: 0.5em;
    padding-right: 0.5em;

}

#noteshistory-selector {
    position: absolute;
    color: white;
    top: 5em;
    min-height: 2.5em;
    z-index: 100;
    margin-left: 10vw;
    font-weight: bold;
    font-size: 1.25em;
}

#selector {
    margin-left: 1em;
}

#noteshistory-list-arrow-more {
    max-width: 5vw;
    transform: translate(90vw,-1.5em);
    font-size: 2em;
    color: transparent;
}

.content {
    position: absolute;
    top: 10em;
    background-color: transparent;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    height: calc(100vh - 10em);
    overflow-y: scroll;
}

#noteshistory-container {
    position: relative;
    margin-left: 5vw;
    z-index: 150;
    width: 90vw;
    min-height: calc(100vh - 13em);
    max-height: calc(100vh - 13em);
    overflow-y: scroll;
}

#noteshistory-container.flash {
    border-bottom: 1px solid #0d2365;
}

#noteshistory-list-arrow-more.flash {
    color: #0d2365;
}

#noteshistory-results.fade {
    opacity: 1;
    max-width: 90vw;
    border-bottom: 1px solid Gray;
    transition: border 0.2s;
}

#noteshistory-list-arrow-more.fade {
    opacity: 1;
    color: DarkGray;
    transition: color 0.2s;
}

#noteshistory-hidden-div{
    height: 1px;
    visibility: hidden;
}


.noteshistory-section {
    max-width: 80vw;
    margin-left: 5vw;
}

.note {
    font-size: 0.9em;
    padding: 1em;
    border: 3px solid #e0e0e0;
    margin-bottom: 1em;
    border-radius: 0.25em;
    background-color: white;
}

.notehistory-bold {
    font-weight: bold;
    font-family: sans-serif;
}

.notehistory-name {
    word-wrap: break-word;
    font-family: sans-serif;
    font-size: 1.1em;
    border-bottom: 2px solid #e0e0e0;
}

.notehistory-nullackby {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    color: Gray;
    padding-left: 1em;
    padding-right: 1em;
}

.notehistory-ackby {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    color: Red;
    font-style: italic;
    padding-left: 1em;
    padding-right: 1em;
}

.notehistory-note {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

.footer {
    position: fixed;
    bottom: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    max-width: 100%;
    min-width: 100%;
    min-height: 1.5em;
    border-top: 1px solid white;
    z-index: 100;
    background-color: #0d2365;
}

</style>
